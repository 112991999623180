let functions = {
    alertPopup(obj) {
        return swal.fire({
            title: obj.title,
            text: obj.text,
            toast: obj.toast ? obj.toast : false,
            position: obj.position ? obj.position : 'center',
            html: obj.html ? obj.html : false,
            customClass: obj.customClass ? obj.customClass : '',
            icon: obj.type ? obj.type : 'warning',
            timer: obj.timer ? obj.timer : false,
            timerProgressBar: obj.timerProgress ? obj.timerProgress : false,
            showCancelButton: (obj.showCancel || obj.showCancel === false) ? obj.showCancel : true,
            showConfirmButton: (obj.showConfirm || obj.showConfirm === false) ? obj.showConfirm : true,
            confirmButtonText: obj.confirmButton,
            cancelButtonText: obj.confirmCancel,
            allowEnterKey: false,
            reverseButtons: (obj.reverseButton || obj.reverseButton == false) ? obj.reverseButton : true
        })
    },
    alertPopupClose() {
        swal.close();
    },
}
export default functions;
