import Vue from 'vue'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import { InertiaApp } from '@inertiajs/inertia-vue'
import { InertiaProgress } from '@inertiajs/progress/src'
//import swal from 'vue-sweetalert2';
import swal from "sweetalert2/dist/sweetalert2";
window.swal = swal;
import 'sweetalert2/dist/sweetalert2.min.css';
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueTheMask from 'vue-the-mask'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueCookies from 'vue-cookies'
import moment from 'moment'
import Notifications from 'vue-notification'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'


Vue.config.productionTip = false
Vue.mixin({ methods: { route: window.route } })
Vue.use(InertiaApp)
Vue.use(PortalVue)
Vue.use(VueMeta)
Vue.use(require('vue-moment'))
Vue.use(Viewer);
Vue.use(VueTheMask)
Vue.component('datetime', Datetime);
Vue.use(VueCookies)
Vue.use(Notifications)
Vue.use(VueLodash, { lodash: lodash })

window.Common = require('./Custom/Common')

InertiaProgress.init()

let app = document.getElementById('app')

/*@ Component will be used to dynamic the colors in Layout.vue */
Vue.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
});

Vue.filter('formatDate', function (value) {
  if (!value) return ''

  return moment(value).format('DD/MM/YYYY')
})

Vue.filter('formatDateAndTime', function (value) {
  if (!value) return ''

  return moment(value).format('DD/MM/YYYY hh:mm a')
})

Vue.filter('phone', function (value) {
  if (!value) return ''
  return value.replace(/(0)(\d{5})(\d{5}|\d{6})/, '($1)-$2-$3')
})

new Vue({
  metaInfo: {
    titleTemplate: (title) => title ? `${title} - Assyst Portal` : 'Assyst Portal'
  },
  render: h => h(InertiaApp, {
    props: {
      initialPage: JSON.parse(app.dataset.page),
      resolveComponent: name => import(`@/Pages/${name}`).then(module => module.default),
    },
  }),
}).$mount(app)
